import React from 'react';
import './SocialFeed.css'; // Importing the CSS file for styles

const SocialFeed = () => {
  const posts = [
    {
      imgSrc: 'SocialImg/social-img1.jpeg',
      text: 'ASSOCHAM announces its mega flagship event GEM 5th Sustainability Conclave, Expo and Awards on the 20-21 January 2023 @TAJ MAHAL hotel, Lucknow.',
    },
    {
      imgSrc: 'SocialImg/social-img2.jpeg',
      text: 'We are proud to have Mehta Tubes Limited as the HVAC PARTNER for ASSOCHAM GEM 5th International Sustainability Conclave, Expo & Awards 2023 scheduled to be held on Jan 20-21 at Hotel Taj Mahal, Lucknow.',
    },
    {
      imgSrc: 'SocialImg/social-img3.jpeg',
      text: <ul>
      <li>GEM Sustainability Certification Program Reference Guide</li>
      <li>ASSOCHAM GEM Reference Guide for Existing Building</li>
      <li>GEM Certified Professional Online Exam Sample Paper</li>
    </ul>
    },
    // {
    //   imgSrc: 'image4.jpg',
    //   text: 'Fourth post: Loving the sunset view!',
    // },
    // {
    //   imgSrc: 'image5.jpg',
    //   text: 'Fifth post: Had an amazing dinner with friends.',
    // },
    // {
    //   imgSrc: 'image6.jpg',
    //   text: 'Sixth post: A lovely day at the beach.',
    // },
  ];

  return (
    <div className="social-feed-main">
      <h2>Social Feed</h2>
    <div className="social-feed">
      {posts.map((post, index) => (
        <div className="Socialcard" key={index}>

          <img src={require(`../assets/${post.imgSrc}`)} alt={`Social ${index + 1}`} />
          
          {/* <div className="social-card-text"> */}
          <p className="social-card-text">{post.text}</p>
          </div>
        // </div>
      ))}
    </div>
    </div>
  );
};

export default SocialFeed;

