// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import './GemCps.css';
// // import Step from '../Step/NewStep';
// // import StepCard from '../Step/StepCard';
// // import StepCircle from '../Step/StepCircle';
// // import StepImg from '../assets/StepImg/step-img-new2.png'
// // import RegistrationSteps from '../CardPages/RegistrationSteps';
// import StepNew from '../StepDesign/StepNew';
// import CpsRegisterForm from './CpsRegisterForm';
// // import StepImg from '../assets/StepImg/stepImg.png'

// const GemCps = () => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [modalOpen, setModalOpen] = useState(false);

//   const performanceData = [
//     { marks: '90 and above', performance: 'Extraordinary performance' },
//     { marks: '70 to 89', performance: 'Excellent performance' },
//     { marks: '50 to 69', performance: 'Good performance' },
//     { marks: 'Below 50', performance: 'Not clear' },
//   ];

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div className="GemCps-bkg">
//     <div>
//       <div className="about-btn">
//         <a href="" className="about-btn-link">
//           Home <span className="separator"> &gt; </span> GEM Certified Professional
//         </a>
//       </div>

//       {/* First View (Initial Content) */}
//       <div className="main-cps-div">
//         <h2>ASSOCHAM GEM Certified Professional (GEM CP) Online Test registration steps –</h2>

//         <div className="note">
//           <p className='cps-para'>
//             ASSOCHAM has initiated the <b> “GEM Sustainability (Green) Certification Program” </b>with an objective to promote environment-friendly green building design and construction. Through this initiative, ASSOCHAM awards the Sustainability Certification Rating to Housing, Urban Development, Residential, Commercial, Hotels, College, Universities, Schools and Factory buildings and developments. All existing, new, and upcoming buildings can participate in this program.
//           </p>

//           <p className='cps-para'>
//             You can become a GEM CP by taking the GEM CP test remotely. You can take the test in the comfort of your home or workplace. This convenience will be valuable to you. The ASSOCHAM team of experts has made this possible for you.
//           </p>
//           <p className='cps-para'>
//             Use the following steps to register yourself for GEM CP online test, make online/offline payment, schedule the test, and appear for the test.
//           </p>
//         </div>
//             <StepNew />
//         {/* Conditionally Render Extra Content */}
//         {/* {isExpanded && ( */}
//           <div className='gem-step-main'>
//           <div className="registration-stepDiv">
//           <h3>GEM CP Online Test Registration Steps</h3>
//       <ol className='Step-links'  style={{ listStyleType: 'none' }}>

//         <li className='stpes-link-list'  >
//           <strong>Step-1 – Registration</strong><br />
//           Register for GEM CP online test using the following link: <br />
//           {/* <a  href="http://www.green-assocham.com/gem_certified_professional.php" target="_blank" rel="noopener noreferrer">
//             http://www.green-assocham.com/gem_certified_professional.php
//           </a> */}
//           <Link to= "/CpsRegisterForm">Click Here</Link>
//         </li>

//         <li className='stpes-link-list' >
//           <strong>Step-2 – Online Payment</strong><br />
//           Once your registration is successful, you will receive the following link to make the payment for GEM CP online test fee:<br />
//           Fee details:<br />
//          <b id='bold-cps'>- Rs. 5,900 for Professionals </b> <br />
//          <b id='bold-cps'> - Rs. 4,720 for Faculties & Students (Including GST)</b><br />
//           Payment options available. You can also contact us for cheque/DD/NEFT payments.<br />
//           Make the online payment using the link:<br />
//           <a href="http://www.green-assocham.com/payments.php" target="_blank" rel="noopener noreferrer">
//             http://www.green-assocham.com/payments.php
//           </a>
//         </li>

//         <li className='stpes-link-list' >
//           <strong>Step-3 – Schedule Your Test</strong><br />
//           After payment, you will receive a link like the one below to schedule your online test.<br />
//           You can schedule the test between Monday to Friday from <b> 10 AM to 5 PM. </b><br />
//           Schedule your test using the following link:<br />
//           <a href="http://www.green-assocham.com/appointment.php?invoice=A-000XXXX" target="_blank" rel="noopener noreferrer">
//             http://www.green-assocham.com/appointment.php?invoice=A-000XXXX
//           </a>
//         </li>


//               {/* 4th step */}
//               <div className="step-4-div"  >
//         <li className='stpes-link-list'>
//           <strong>Step-4 – Take the Online Test</strong><br />
//           You will receive a link to take the online test as per your scheduled time.<br />
//           Take the test using the following link:<br />
//           <a href="http://www.green-assocham.com/quiz.php?invoice=A-0000XXX" target="_blank" rel="noopener noreferrer">
//             http://www.green-assocham.com/quiz.php?invoice=A-0000XXX
//           </a>
//           </li>
       
      


//           <div className="extra-content" >
//             <ul className='list-itmes-cps' >
//               <li className='CpsList'>Your test will be started on the scheduled time only. Paste the above link in a new tab at or just after the scheduled time and press enter. Your test will be started.</li>
//               <li className='CpsList' >You will be given 5 minutes to read the instructions and 90 minutes for taking the test. Read all the instructions and questions carefully.</li>
//               <li className='CpsList' >
//                 There will be 100 questions in the test (Both objective type-single answer and True/False).
//               </li>
//               <li className='CpsList' >During the test, you can move to any previous or next questions by clicking on the “Previous” or “Next” tab respectively.</li>
//               <li className='CpsList' >You can review all the attempted questions by clicking on the “Review & Submit” tab once.</li>
//               <li className='CpsList' >Once you are in the Test Review section, you can move to any question by just clicking on that question directly.</li>
//               <li className='CpsList' >You can finally submit the test by clicking on the “Final Submit” tab.</li>
//               <li className='CpsList' >Do not close the test window during the test.</li>
//               <li className='CpsList' >Pass criteria –</li>
              
//               <div className="table-div">
//                 <table className='table-body' style={{ width: '47%', border: '1px solid black', borderCollapse: 'collapse', textAlign: 'justify', justifyContent: 'center' }}>
//                   <thead>
//                     <tr style={{background: '#8aaf3b'}}>
//                       <th style={{ border: '1px solid black', padding: '8px' }}>Marks Obtained</th>
//                       <th style={{ border: '1px solid black', padding: '8px' }}>Performance Criteria</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {performanceData.map((item, index) => (
//                       <tr key={index}>
//                         <td style={{ border: '1px solid black', padding: '8px' }}>{item.marks}</td>
//                         <td style={{ border: '1px solid black', padding: '8px' }}>{item.performance}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
                
//               </div>
//             </ul>
//             </div>
//           </div>
//           <li className='stpes-link-list'>
//           <strong>Step-5 – Download the Reference Guide</strong><br />
//           The GEM Sustainability Certification Program reference guide can be downloaded from our website for FREE using the following link:<br />
//           <a href="http://green-assocham.com/news.php?id=10" target="_blank" rel="noopener noreferrer">
//             http://green-assocham.com/news.php?id=10
//           </a>
//         </li>
//           {/* ol end */}
//           </ol>
//           </div>
//           </div>
//         {/* )} */}

//         {/* Expand More Button */}
//         {/* <div className="expand-btn-container">
//           <button className="expand-btn" onClick={toggleExpand}>
//             {isExpanded ? 'Show Less' : 'Expand More'}
//           </button> */}
//         </div>

//         {/* Register and Sample Paper Buttons */}
//         <div className="button-div-cps">
//           <ul>
//             <Link to='/register'>
//               <li className='Cps-btn' id='register-yourself'>Register Yourself</li>
//             </Link>
//             <Link to='/sample-paper'>
//               <li className='Cps-btn' id='sample-paper'>Sample Paper</li>
//             </Link>
//           </ul>
//         </div>
//       </div>

//       {/* form */}
      

//       {/* form */}
     
//     </div>
//     // </div>
//   );
// };

// export default GemCps;

// new code

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import './GemCps.css';
import StepNew from '../StepDesign/StepNew';  // Your custom step component
import CpsRegisterForm from './CpsRegisterForm'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faHandPointRight, faLocationArrow } from '@fortawesome/free-solid-svg-icons';

// Make sure to set the root element for the modal
// Modal.setAppElement('#root');

const GemCps = () => {
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

  const performanceData = [
    { marks: '90 and above', performance: 'Extraordinary performance' },
    { marks: '70 to 89', performance: 'Excellent performance' },
    { marks: '50 to 69', performance: 'Good performance' },
    { marks: 'Below 50', performance: 'Not clear' },
  ];

  const openModal = () => {
    setModalOpen(true); // Open the modal when the link is clicked
  };

  const closeModal = () => {
    setModalOpen(false); // Close the modal
  };

  return (
    <div className="GemCps-bkg">
      <div className="about-btn">
        <a href="" className="about-btn-link">
          Home <span className="separator"> &gt; </span> GEM Certified Professional
        </a>
      </div>

      {/* Main Content */}
      <div className="main-cps-div">
        <h2>ASSOCHAM GEM Certified Professional (GEM CP) Online Test registration steps –</h2>

        <div className="note">
          <p className='cps-para'>
            ASSOCHAM has initiated the <b> “GEM Sustainability (Green) Certification Program” </b> with an objective to promote environment-friendly green building design and construction. Through this initiative, ASSOCHAM awards the Sustainability Certification Rating to Housing, Urban Development, Residential, Commercial, Hotels, College, Universities, Schools, and Factory buildings and developments. All existing, new, and upcoming buildings can participate in this program.
          </p>
          <p className='cps-para'>
            You can become a GEM CP by taking the GEM CP test remotely. You can take the test in the comfort of your home or workplace. This convenience will be valuable to you. The ASSOCHAM team of experts has made this possible for you.
          </p>
          <p className='cps-para'>
            Use the following steps to register yourself for GEM CP online test, make online/offline payment, schedule the test, and appear for the test.
          </p>
        </div>
        <StepNew />

        {/* Registration Steps */}
        <div className='gem-step-main'>
          <div className="registration-stepDiv">
            <h3>GEM CP Online Test Registration Steps</h3>
            <ol className='Step-links' style={{ listStyleType: 'none' }}>
              <li className='stpes-link-list'>
                <strong>Step-1 – Registration</strong><br />
                Register for GEM CP online test using the following link: <br />
                <Link to="/CpsRegisterForm" target='_blank' >Click Here</Link> 
                {/* <Link to="/CpsRegisterForm" onClick={openModal}>Click Here</Link>  */}
              </li>

              <li className='stpes-link-list'>
                <strong>Step-2 – Online Payment</strong><br />
                Once your registration is successful, you will receive the following link to make the payment for GEM CP online test fee:<br />
                Fee details:<br />
                <b id='bold-cps'>- Rs. 5,900 for Professionals </b> <br />
                <b id='bold-cps'>- Rs. 4,720 for Faculties & Students (Including GST)</b><br />
                Payment options available. You can also contact us for cheque/DD/NEFT payments.<br />
                Make the online payment using the link:<br />
                <a href="http://www.green-assocham.com/payments.php" target="_blank" rel="noopener noreferrer">
                  {/* http://www.green-assocham.com/payments.php */}
                  click Here
                </a>
              </li>

              <li className='stpes-link-list'>
                <strong>Step-3 – Schedule Your Test</strong><br />
                After payment, you will receive a link to schedule your online test.<br />
                You can schedule the test between Monday to Friday from <b> 10 AM to 5 PM. </b><br />
                Schedule your test using the following link:<br />
                <a href="http://www.green-assocham.com/appointment.php?invoice=A-000XXXX" target="_blank" rel="noopener noreferrer">
                  {/* http://www.green-assocham.com/appointment.php?invoice=A-000XXXX */}
                  click Here
                </a>
              </li>

              {/* 4th step */}
              <div className="step-4-div">
                <li className='stpes-link-list'>
                  <strong>Step-4 – Take the Online Test</strong><br />
                  You will receive a link to take the online test as per your scheduled time.<br />
                  Take the test using the following link:<br />
                  <a href="http://www.green-assocham.com/quiz.php?invoice=A-0000XXX" target="_blank" rel="noopener noreferrer">
                    {/* http://www.green-assocham.com/quiz.php?invoice=A-0000XXX */}
                    click Here
                  </a>
                </li>

                <div className="extra-content">
                  <ul className='list-itmes-cps'>
                    <li className='CpsList'>
                    <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />

                      Your test will be started on the scheduled time only. Paste the above link in a new tab at or just after the scheduled time and press enter. Your test will be started.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      You will be given 5 minutes to read the instructions and 90 minutes for taking the test. Read all the instructions and questions carefully.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      There will be 100 questions in the test (Both objective type-single answer and True/False).
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      During the test, you can move to any previous or next questions by clicking on the “Previous” or “Next” tab respectively.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      You can review all the attempted questions by clicking on the “Review & Submit” tab once.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      Once you are in the Test Review section, you can move to any question by just clicking on that question directly.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      You can finally submit the test by clicking on the “Final Submit” tab.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      Do not close the test window during the test.
                      </div>
                      </li>
                    <li className='CpsList'>
                    <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <FontAwesomeIcon icon={faLocationArrow} style={{rotate: '45deg', fontSize: '20px', color: 'green'}} />
                      Pass criteria –
                      </div>
                      </li>

                    <div className="table-div">
                      <table className='table-body' style={{ width: '47%', border: '1px solid black', borderCollapse: 'collapse', textAlign: 'justify', justifyContent: 'center' }}>
                        <thead>
                          <tr style={{ background: '#8aaf3b' }}>
                            <th style={{ border: '1px solid black', padding: '8px' }}>Marks Obtained</th>
                            <th style={{ border: '1px solid black', padding: '8px' }}>Performance Criteria</th>
                          </tr>
                        </thead>
                        <tbody>
                          {performanceData.map((item, index) => (
                            <tr key={index}>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{item.marks}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{item.performance}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </ul>
                </div>
              </div>
              <li className='stpes-link-list'>
                <strong>Step-5 – Download the Reference Guide</strong><br />
                The GEM Sustainability Certification Program reference guide can be downloaded from our website for FREE using the following link:<br />
                <a href="http://green-assocham.com/news.php?id=10" target="_blank" rel="noopener noreferrer">
                  {/* http://green-assocham.com/news.php?id=10 */}
                  click Here
                </a>
              </li>
            </ol>
          </div>
        </div>

        {/* Modal for Registration Form */}
        {/* <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="GEM CP Registration Form"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <div className="modal-content">
            <div className="close-top-btn-model">
            <button onClick={closeModal} className="close-modal-btn">X</button>
            </div>

            <CpsRegisterForm />
          </div>
        </Modal> */}

        {/* Other Content */}
        <div className="button-div-cps">
          <ul>
            <Link to='/register'>
              <li className='Cps-btn' id='register-yourself'>Register Yourself</li>
            </Link>
            <Link to='/sample-paper'>
              <li className='Cps-btn' id='sample-paper'>Sample Paper</li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GemCps;
