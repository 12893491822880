import React, { useState } from 'react';
import './Resource.css';
import pdf1 from '../assets/pdfFolder/demoPdf.pdf';
import pdf2 from '../assets/slider-img-new1.jpeg';
import pdf3 from '../assets/slider-img2.jpeg';
import { Link } from 'react-router-dom';
import '../pages/AboutUs.css';
import axios from 'axios';

const pdfResources = [
    { title: 'Download PDF 1', link: pdf1 },
    { title: 'Download PDF 2', link: pdf2 },
    { title: 'Download PDF 3', link: pdf3 }
];

const Resource = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        designation: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Save user data to the database using Axios
            await axios.post(`${process.env.REACT_APP_API_URL}/api/userData`, formData);

            // Clear the form data
            setFormData({
                name: '',
                email: '',
                phone: '',
                designation: '',
                message: ''
            });

            // Open the selected PDF
            if (selectedPdf) {
                window.open(selectedPdf, '_blank');
                setModalOpen(false); // Close modal after submission
            }
        } catch (error) {
            console.error('Error:', error);
            // If there's an error, you can choose to show an alert or keep the modal open
            alert('Failed to submit data. Please try again.');
        }
    };

    const handleSelectChange = (link) => {
        setSelectedPdf(link);
        setModalOpen(true); // Open modal on selection
    };

    return (
        <div>
       
        <div className="resource-container">
            <h2>Available Resources</h2>

            {pdfResources.map((pdf, index) => (
                <div key={index} className="pdf-dropdown">
                    <select onChange={() => handleSelectChange(pdf.link)} defaultValue="">
                        <option value="" disabled>{pdf.title}</option>
                        <option value="open">Open PDF</option>
                    </select>
                </div>
            ))}

            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
                        <h3>Register to Access PDF</h3>
                        <form onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Name" 
                                value={formData.name} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Email" 
                                value={formData.email} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="tel" 
                                name="phone" 
                                placeholder="Phone" 
                                value={formData.phone} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="text" 
                                name="designation" 
                                placeholder="Designation" 
                                value={formData.designation} 
                                onChange={handleChange} 
                                required 
                            />
                            <textarea 
                                name="message" 
                                placeholder="Message" 
                                value={formData.message} 
                                onChange={handleChange} 
                                required 
                            ></textarea>
                            <button type="submit">Register</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
};

export default Resource;
