import React from 'react'
import '../CardPages/GemGreenCerti.css'
import '../CardPages/Iso.css'

const WaterAudit = () => {
  return (
    <div>
    {/* <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>Water Audit</h2>
        <div className="GEMCP-btn"> */}
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span>Energy & Water Audit
          </a>
        {/* </div> */}
       
      {/* </div> */}
    {/* </div> */}
    {/* <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div> */}
        <div className="Iso-container">

            {/* <h2>Water Audit</h2> */}
            </div>
            <div className="text-para-div">
           {/* <h3>ISO 14001 Environment Management System Audits</h3> */}
          <p>
          Water audits are important for residential, commercial, and industrial context. Water audits enable organisations to identify, verify, quantify and develop resolutions for improved water management inside the campus. the exercise is useful for process industry, buildings and other commercial establishments including infrastructure projects. ASSOCHAM has a pool of competent water auditors for undertaking water audits in diverse settings. 

          </p>
        </div>
        
  </div>
  )
}

export default WaterAudit
