// import React from "react";
// import './CopyRight.css'; // Ensure to create this CSS file

// const CopyRight = () => {
//   return (
//     <div className="copyright-container">
//       <span>© 2018-2022. All rights reserved</span>
//       <div className="links">
//         <a href="#">Terms & Conditions</a>
//         <span>|</span>
//         <a href="#">Privacy Policy</a>
//         <span>|</span>
//         <a href="#">Videos</a>
//         <span>|</span>
//         <a href="#">PAYMENT PAGE</a>
//       </div>
//     </div>
//   );
// };

// export default CopyRight;

// new code
import React from "react";
import './CopyRight.css';

const CopyRight = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="copyright-container">
      <span>© 2018-{currentYear}. All rights reserved</span>
      <div className="links">
        <a href="#">Terms & Conditions</a>
        <span>|</span>
        <a href="#">Privacy Policy</a>
        <span>|</span>
        <a href="#">Videos</a>
        <span>|</span>
        <a href="#">PAYMENT PAGE</a>
      </div>
    </div>
  );
};

export default CopyRight;