
import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs'
// import Navbar from './componant/Navbar'; 
import SubHeader from './componant/SubHeader'; // Ensure the path is correct
import Footer from './componant/Footer';
import GemGreen from './pages/GemGreen';
import SustainabilityPage from './pages/SustainabilityPage';
import GemCp from './pages/GemCp';
import Event from './pages/Event';
import Resources from './pages/Resources';
// import Register1 from './pages/Register1';
import NewNavbar from './componant/NewNavbar';
// import GeoEcoSystem from './pages/GeoEcoSystem';
import CopyRight from './componant/CopyRight';
import GemGreenCerti from './CardPages/GemGreenCerti';
import GemProductCerti from './CardPages/GemProductCerti';
import CarbonFoot from './CardPages/CarbonFoot';
// import SustainabilityAssessment from './CardPages/SustainabilityAssessment';
import Iso from './CardPages/Iso';
import WaterAudit from './CardPages/WaterAudit';
import EnergyAudit from './CardPages/EnergyAudit';
import ContactUs from './pages/ContactUs';
import GemCps from './NavbarPages/GemCps';
import Resource from './componant/Resource';
import NewResource from './componant/NewResource';
import NewGeoEcoSystem from './pages/NewGeoEcoSystem';
import Register2 from './pages/Register2';
import EventDetails from './EventsPage/EventDetails';
import UpcomingEvent from './EventsPage/UpcomingEvent';
import Gallery from './pages/Gallery';
import FAQ from './componant/Faq';
import PastEvent from './EventsPage/PastEvent';
import EcoProduct from './componant/EcoProduct';
import ExcelUpload from './ExcelUpload/ExcelUpload';
import GemRating from './pages/GemRating';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpsRegisterForm from './NavbarPages/CpsRegisterForm';
import LeanAssessment from './CardPages/LeanAssessment';

import NewAboutUs from './componant/NewAboutUs';
import UpdatedSustain from './CardPages/UpdatedSustain'
import EnviromentalService from './CardPages/EnviromentalService';
import UpdatedSustainNew from './CardPages/UpdatedSustainNew';
import BottomTop from './pages/BottomTop';
import NewGemRating from './pages/NewGemRating';
// import StepLayout from './SliderPages/StepLayout';

// import NewGeoEcoSystem from './pages/NewGeoEcoSystem';
// import EcoSystem from './pages/EcoSystem';


export default function App() {
  return (
    <HashRouter>
    {/* <BrowserRouter> */}
      {/* <SubHeader /> */}
      {/* <Navbar /> */}
      < NewNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<NewAboutUs />} />
        <Route path="/gem-rating" element={<NewGemRating />} />
        {/* <Route path="/gem-ratingNew" element={<NewGemRating />} /> */}
        <Route path="/gemGreen" element={<Gallery />} />
        {/* <Route path="/gemGreen" element={<GemGreen />} /> */}
        <Route path="/Sustainability" element={<UpdatedSustainNew />} />
        {/* <Route path="/Sustainability" element={<SustainabilityPage />} /> */}
        {/* <Route path="/gemCp" element={<ExcelUpload />} /> */}
        <Route path="/gemCp" element={<GemCps />} />
        {/* <Route path="/ecoSystem" element={<NewGeoEcoSystem />} /> */}
        <Route path="/eco-products" element={<EcoProduct />} />
        <Route path="/lean-assessment" element={<LeanAssessment />} />
        {/* <Route path="/events" element={<Event />} /> */}
        <Route path="/upcoming-events" element={<UpcomingEvent />} />
        <Route path="/past-events" element={<PastEvent />} />

        <Route path="/register" element={<Register2 />} />
        <Route path="/gem-chapter" element={<NewGeoEcoSystem />} />
        {/* <Route path="/resources" element={<Resources />} /> */}
        <Route path="/contact-Us" element={<ContactUs />} />
        <Route path="/cps" element={<ExcelUpload />} />


        {/* Uncomment and add routes as needed */}
        {/* <Route path="/about" element={<About />} /> */}

        <Route path="/gem-green" element={<GemGreenCerti />} />
        <Route path="/gem-product" element={<GemProductCerti />} />
        <Route path="/carbon-footprint" element={<CarbonFoot />} />
        <Route path="/environmental-service" element={<EnviromentalService />} />
        <Route path="/sustainability-assessment" element={<UpdatedSustainNew />} />
        {/* <Route path="/sustainability-assessment" element={<SustainabilityAssessment />} /> */}
        <Route path="/iso-14001" element={<Iso />} />
        <Route path="/energy&water-audits" element={<WaterAudit />} />
        {/* <Route path="/energy-audits" element={<EnergyAudit />} /> */}
        {/* <Route path="/cps" element={<GemCps />} /> */}
        <Route path="/resources" element={<NewResource />} />
        {/* <Route path="/resources" element={<Resource />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/event/:id" element={<EventDetails />} />
        <Route path="/CpsRegisterForm" element={<CpsRegisterForm />} />

        {/* Footer links  */}
        <Route path="/faq" element={<FAQ />} />
      </Routes>
      {/* <StepLayout /> */}
      <Footer />
      <BottomTop />
      <CopyRight />
    </HashRouter>
    // </BrowserRouter>
  );
}
