import React, { useState, useEffect } from 'react';
import './Home.css';

// Import images from the assets folder
import image1 from '../assets/slider-img-new1.jpeg';
import image2 from '../assets/slider-img-new2.jpeg';
import image3 from '../assets/slider-img2.jpeg';
import image4 from '../assets/slider-img-new3.jpeg';
import image5 from '../assets/slider-img4.jpeg';
import image6 from '../assets/slider-img5.jpeg';
// import Statistics from './Statistics';
// import Statistics1 from './Statistics1';
// import Sustainability from '../componant/Sustainability';
import Story from '../componant/Story';
import SocialFeed from '../componant/SocialFeed';
import StatisticsNew from './StatisticsNew';
import SustanabilityVer1 from '../componant/SustanabilityVer1';
import FAQ from '../componant/Faq';
import Gallery from '../SliderPages/Gallery';
import SliderMainpage from '../SliderPages/SliderMainpage';

const images = [image1, image2, image3, image4, image5, image6];

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === images.length - 1 ? 0 : prevImage + 1
    );
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  // Automatically change image every 10 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 10000); // Change image every 10 seconds
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [currentImage]);

  return (
    <>
    <div className="carousel-container">
      <div className="carousel">
        <img src={images[currentImage]} alt={`Slide ${currentImage}`} className="carousel-image" />
        
        {/* Left Button */}
        <button className="carousel-button left" onClick={prevImage}>
          &#10094;
        </button>
        
        {/* Right Button */}
        <button className="carousel-button right" onClick={nextImage}>
          &#10095;
        </button>
      </div>

      {/* Optionally, add dots to indicate the current image */}
      {/* <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentImage === index ? 'active' : ''}`}
            onClick={() => setCurrentImage(index)}
          ></span>
        ))}
      </div> */}
      {/* <Statistics /> */}
      
    </div>
    <StatisticsNew />
    {/* <Statistics1 /> */}
    {/* <Sustainability /> */}
    <SustanabilityVer1 />
    <SliderMainpage />
    <Story />
    
    {/* <Gallery /> */}
    < SocialFeed />
    <FAQ />
    </>
  );
};

export default Home;
