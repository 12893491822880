import React, { useState } from 'react';
import './Faq.css'; // Import the CSS file
import { toast } from 'react-toastify';


const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [userQuestion, setUserQuestion] = useState('');

    const faqs = [
        {
            question: 'What is GEM?',
            answer: (
                <div className="answer-div">
                    <p>
                        The Green and Eco-friendly Movement (GEM) at ASSOCHAM took its roots from a national consultation of experts and consultants in 2017 comprising of renowned architects, planners, MEP consultants and engineers.
                    </p>
                    <p>
                        The GEM team was established as a rating system based on ECBC, National Building Codes and other guidelines. The first version of the GEM green building certification rating was released in 2018.
                    </p>
                </div>
            )
        },
        {
            question: 'What are sustainable buildings?',
            answer: (
                <ul className="faqListItem">
                    <li>Sustainable buildings can improve the environment's ecology in numerous ways.</li>
                    <li>Energy savings of 20-30%.</li>
                    <li>Reduced water usage by 30-50%.</li>
                    <li>Significantly reduce waste generation by recycling and reusing.</li>
                    <li>Sustainable buildings are more competitive and nature-friendly than conventional buildings.</li>
                    <li>The long-term benefits such as low operating costs along with potential health benefits for the occupiers make it a viable option.</li>
                </ul>
            )
        },
        {
            question: 'Why go for GEM certification?',
            answer: (
                <ul className="faqListItem">
                    <li>GEM has demonstrated multi-facet benefits in the projects rated by the programme.</li>
                    <li>GEM is in line with ECBC, NBC and other national guidelines.</li>
                    <li>Projects normally have a payback of 6-24 months.</li>
                </ul>
            )
        },
        {
            question: 'What are the benefits of GEM Certification?',
            answer: (
                <>
                    <p>Key tangible benefits which have been accrued are:</p>
                    <ul className="faqListItem1">
                        <li>Improved health of residents</li>
                        <li>Reduced energy consumption</li>
                        <li>Reduced water loads.</li>
                        <li>Better HVAC management</li>
                        <li>Well-planned waste management</li>
                        <li>Sustainable design and architecture</li>
                        <li>Enhanced landscaping for better aesthetics and visibility</li>
                        <li>Improved project marketing</li>
                        <li>Customer trust and confidence as eco-friendly property</li>
                    </ul>
                </>
            )
        },
        { 
            question: 'How can I contact customer service?', 
            answer: 'You can reach us via email or phone listed on our contact page.' 
        },
    ];

    const handleQuestionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (userQuestion) {
            // alert(`Your question has been submitted: ${userQuestion}`);
            toast.success('Question submitted successfully!')
            setUserQuestion(''); // Clear the input field
        }
    };

    return (
        <div className="main-faq-div">
        <div className="faq-container">
            {/* <div className="faq-flex-div"> */}
            <h1 className='faq-heading-text'>Frequently Asked Questions</h1>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div className="faq-question" onClick={() => handleQuestionClick(index)}>
                            <h3>{faq.question}</h3>
                            <span className={`toggle-icon ${activeIndex === index ? 'rotate' : ''}`}>
                                {activeIndex === index ? '-' : '+'}
                            </span>
                        </div>
                        {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
                    </div>
                    
                ))}
            {/* </div> */}
            </div>

            <div className="ask-question">
                <h2>Ask Your Question</h2>
                <form onSubmit={handleSubmit}>
                    <textarea id='ask-question-text-area'
                        value={userQuestion}
                        onChange={(e) => setUserQuestion(e.target.value)}
                        placeholder="Type your question here..."
                        required
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
        </div>
    );
};

export default FAQ;
