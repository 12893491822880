import React from 'react';
import './Gallery2.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';
import img1 from '../assets/EventImg/Event-img-1.jpg'
import img2 from '../assets/PdfBanner/image_29.webp'
import img3 from '../assets/PdfBanner/pdfBanner-2.webp'

const Gallery = () => {
    const cards = [
        { id: 1, title: 'Suparshwanath, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-8.webp' },
        { id: 2, title: 'Shraddha CHS Ltd., Navi Mumbai', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-9.webp' },
        { id: 3, title: 'Alaya Apartment, Jaipur', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-1.webp' },
        { id: 4, title: 'PPI System, Vatva, Ahmedabad', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-4.webp' },
        { id: 5, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-5.webp' },
        { id: 6, title: '122, Wakad, Commercial Project, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-6.webp' },
    ];

    return (
        <div>
            <div className="gallery-main-bkg">
            <div className="about-main-div">
        {/* <div className="aboutButtonDiv"> */}
          {/* <h2>About Us</h2> */}
          <div className="about-btn">
            <a href="" className="about-btn-link">
         <Link to='/' >   Home</Link>   <span className="separator"> &gt; </span> GEM Green Certification
            </a>
          {/* </div> */}
         
        </div>
      </div>

      <div className="Gallery-text-paragraph">
    
      
        <div className="Gallery-para-text-div">
          
        <h3>GEM Green building certification </h3>
        
            <p className="gallery-text">
            The GEM green building certification is an indigenous systematic evidence-
            based rating system for built environment including commercial and residential 
            complexes. It is designed on the principles of NBC 2016, ECBC 2017 and 
            selected CPWD guidelines. The objective is to ensure future buildings move 
            towards environment friendly methods of design, construction, and operations. 
            The GEM certification programme has so far successfully rated many projects 
            across the country. The projects range from residential to high rise, mixed land 
            use, interior, institutional, commercial, factory, hotel, hospital as well as public 
            transportation including airports and railway stations. 
            </p>

            <p className="gallery-text">
            GEM has completed more than 200 projects and is supported by an ecosystem 
            of more than 2000 architects, MEP consultants, GEM CP, sectoral experts and 
            others across the country. The GEM green building certification offers Pre-
            certification (for green field/design phase or under construction) and final 
            certification for (new and existing buildings). It offers a unique preposition for 
            enabling tangible and indirect savings throughout the life cycle of the projects 
            with respect to energy, materials, HVAC costs and many more aspects. 
            </p>

           
        </div>
      </div>

        <div className="gallery-container">
            {cards.map(card => (
                <div key={card.id} className="card">
                    <img src={card.imgSrc} alt={card.title} />
                    <div className="title-overlay">
                    <h3>{card.title}</h3>
                    </div>
                </div>
            ))}
        </div>
        </div>

        </div>
    );
};

export default Gallery;
