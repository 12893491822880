import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import './UpdatedsustainNew.css'
import SustainImg from '../assets/rating-img/Image-sustain.png'
import PointerImg from '../assets/rating-img/Pointer-image.png'

const UpdatedSustainNew = () => {
  return (
    <div>
      <div className="sustain-main-div">
      <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> Sustainability Assessment Programme (SAP)
          </a>
        </div>
        <div className="sustain-box-div">
            <div className="sustain-para-img-div">
                <div className="sustain-para-box">
                    <div className="sustain-heading-div">
                        <p className="sustain-para-heading-text">
                        The SAP is a holistic 360-degree assessment of an organisation’s intention and ability to undertake suitable evaluation in terms of sustainability pillars.
                        </p>
                    </div>
                    <div className="sustain-list-main-box">
                    <div className="sustain-list-box1">
                        <ul className="sustain-list-items-1">
                            <h3 className='sustain-heading'>Objectives of SAP</h3>
                            <li className="list-of-sustain1">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    <p>Evaluate the environmental impacts of process</p>
                                </div>
                            </li>
                            <li className="list-of-sustain1">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p>Undertake a socio-economic impact assessment</p>
                                    
                                </div>
                            </li>
                            <li className="list-of-sustain1">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p> Provide a green product certification</p>
                                </div>
                            </li>
                            <li className="list-of-sustain1">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p> Provide a carbon footprinting certification</p>
                                </div>
                            </li>
                            <li className="list-of-sustain1">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p>  Integrate the above objectives for producing a sustainability assessment report</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="sustain-list-box2">
                        <ul className="sustain-list-items-2">
                        <h3 className='sustain-heading'>Scope of Work of SAP</h3>
                            <li className="list-of-sustain-2">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p>GHG based Life Cycle Assessment</p>
                                </div>
                            </li>
                            <li className="list-of-sustain-2">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p>Carbon Footprinting</p>
                                </div>
                            </li>
                            <li className="list-of-sustain-2">
                            <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <div className="list-style-box">
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                    <p>Green Product certification</p>
                                </div>
                            </li>
                            <li className="list-of-sustain-2">
                                <div className="list-style-box">
                                <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                    {/* <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green', fontSize: '20px'}}/> */}
                                        <p>Socio-economic impact assessment</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="sustain-img-box">
                    <img src={SustainImg} alt="" />
                </div>
                {/* flex div */}
            </div>
            {/* flex div */}
        </div>
      </div>
    </div>
  )
}

export default UpdatedSustainNew
