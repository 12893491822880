import React from 'react'
import '../CardPages/GemGreenCerti.css'
import '../CardPages/Iso.css'
import IsoImg1 from '../assets/iso-img/Iso-img-1.png'
import IsoImg2 from '../assets/iso-img/iso-img-2.png'
import IsoPointer from '../assets/iso-img/Pointer-iso.png'

const Iso = () => {
  return (
    <div>
        <div className="iso-main-div">

          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> ISO 14001
          </a>
          <div className="iso-content-box">
            <div className="iso-heading-div">
              <h3 className="iso-heading">ISO 14001</h3>
            </div>
            <div className="iso-flexBox-1">
              <div className="iso-img-box-1">
                <img src={IsoImg1} alt="" />
              </div>
              <div className="iso-text-box-1">
                <p className="iso-para-text">ISO-14001 is an internationally accepted certification on Environmental Management Systems (EnMS). The purpose of this International Standard is to provide organizations with a framework to protect the environment and respond to changing environmental conditions in balance with socio-economic needs. The ISO 14001 dwells into environmental aspects and impacts of any organisation intending to demonstrate intend and leadership in environmental conservation. The ISO 14001 is based on Plan-Do-Check and act (PDCA) model.</p>
                <p className="iso-para-text">
                  <ul className="iso-list-box">
                    <h4 className="list-heading-iso">ISO 14001 assist in accruing several benefits</h4>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Stakeholders’ trust on environmental aspects of organisation.</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                        <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Identifying environmental aspects and impacts of organisation activities.</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Obtain a recognised certification as per international framework.</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Strengthen business excellence in environmental management.</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Assist in building a strong regulatory compliance system. </p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Engage internal and external stakeholders for strategic business growth in line with environmental needs.</p>
                    </li>
                  </ul>
                </p>
                <p className="iso-para-text">ISO 14001 is conducted through a comprehensive audit including sensitisation of management and employees about the standard and other environmental aspects. It is followed by a structured process as guided by the auditing principles and procedures. </p>
              </div>
              {/* flex */}
            </div>
            {/* flex */}

            <div className="iso-flexBox-2">
            <div className="iso-text-box-2">
            <p className="iso-para-text">
            <h4 className="list-heading-iso">Contents of this International Standard</h4>
            This International Standard conforms to ISO’s requirements for management system standards. These requirements include a high-level structure, identical core text, and common terms with core definitions, designed to benefit users implementing multiple ISO management system standards. ISO 14001 also includes a comprehensive documentation requirement, towards the compliance of the standard.
            </p>

            <p className="iso-para-text">
            <ul className="iso-list-box">
            <h4 className="list-heading-iso">The ISO 14001 contains the following core sections in addition to scope, normative references and terms and conditions:</h4>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Context of the organisation</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Leadership</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Planning</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Support</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Operations</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Performance Evaluation</p>
                    </li>
                    <li className="iso-list-item">
                      <div className="iso-list-pointer-img">
                      <img src={IsoPointer} alt="" />
                      </div>
                      <p className="iso-list-text">Improvement</p>
                    </li>

            </ul>
            </p>
           
            </div>
            <div className="iso-img-box-2">
              <img src={IsoImg2} alt="" />
            </div>

            </div>
            <p className="iso-para-text" style={{marginTop: '-9px', paddingBottom: '20px', textAlign: 'center', margin: 'auto', color: '#11253f'}}><b>Disclaimer (as quoted from ISO-14001):</b> Adoption of this International Standard, however, will not in itself guarantee optimal environmental outcomes.</p>

          </div>
        
       
      </div>
  </div>
  )
}

export default Iso
