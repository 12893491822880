import React from 'react'
import './NewAboutUs.css';
import svg1 from '../assets/rating-img/about-svg/priortiesicon1.png'
import svg2 from '../assets/rating-img/about-svg/priortiesicon2.png'
import svg3 from '../assets/rating-img/about-svg/priortiesicon3.png'
import svg4 from '../assets/rating-img/about-svg/priortiesicon4.png'
import AboutImg1 from '../assets/aboutUs-img/aboutimage-1.png'
import AboutImg2 from '../assets/aboutUs-img/about-Image 2.png'
// import { svg } from 'leaflet';

const NewAboutUs = () => {

    const Card = ({ title, text, svg, color }) => {
        return (
            <div className="card-about-box">
          <div className="card-about">
           
            <div className="card-img-container">
              <img src={svg} alt={title} className="card-img-about" />
            </div>
            
            <h3 className="card-title-about">{title}</h3>
            <p className="card-text-about">{text}</p>
            <div className="card-bg-about" style={{ backgroundColor: color }}></div>
            </div>
          </div>
        );
      };


    const cardData = [
        {
          title: 'Sustainability',
          text: 'Focus on managing the needs of today, while safeguarding the interests of future generations. Sustainability is becoming central to decision-making globally, as governments, businesses, and civil society embrace a socially responsible framework to enhance environmental protection.',
          svg: svg1,
          // color: '#4CAF50',
          color: 'white',
        },
        {
          title: 'Empowerment',
          text: 'Empowering all groups of society is a prerequisite for holistic socio-economic growth and development, as it enables people to exercise control and increase self-efficacy. Empowerment is essential to ensure all groups contribute to and fairly benefit from growth processes in a country.',
          svg: svg2,
        //   color: '#FF9800',
        // color: '#4CAF50',
        color: 'white',
        },
        {
          title: 'Entrepreneurship',
          text: 'Entrepreneurship helps a country unlock sector potential and create long-term value. The Indian entrepreneurial spirit has strengthened owing to a supportive regulatory ecosystem, advancements in technology, changing consumer needs, and innovation.',
          svg: svg3,
        //   color: '#3F51B5',
        // color: '#4CAF50',
        color: 'white',
        },
        {
          title: 'Digitalisation',
          text: 'Technology is transforming the way of life through the digitalization of businesses and governments. Technology is improving experiences, making communication effective, and processes more efficient; thereby supporting the overall growth of the economy.',
          svg: svg4,
        //   color: '#9C27B0',
        // color: '#4CAF50',
        color: 'white',
        },
      ];

  return (
    <div>
      <div className="aboutUs-main-bg">

      <div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> About Us
          </a>
        </div>
      </div>


      <div className="after-before-of-about">
        <div className="about-text-div-container">
            <div className="about-text-para-div">
              <div className="aboutUs-flex-box1">
                <div className="about-text-box-1">
                <p className="about-text">ASSOCHAM was established in 1920. ASSOCHAM is the oldest industry association of India, operating since 1920. As the Apex National Business Chamber, it has the unique distinction of representing over 4.5 lakh large, medium & small-scale companies. The century-old organisation, with its principles of SEED: sustainability, empowerment, entrepreneurship, and digitisation serve as a bridge between the industry and government on policy and regulatory, ease of business and cross cutting sectoral issues.</p>

                <p className="about-text">ASSOCHAM has also been accredited as an observer organisation by the UNFCCC. ASSOCHAM has been steadily and consciously improving efforts across key industrial as well as service sectors which are important for the country’s Nationally Determined Targets for achieving Net neutrality</p>

                <p className="about-text">ASSOCHAM participated in the UNFCCC COP26, Glasgow & COP27, Egypt, and supports the LiFE initiative, a clarion call from our Hon’ble Prime Minister for bringing in changes in individual lifestyles for a climate-resilient, sustainable, and just future. The G20 presidency also encourages pathways on the environment and natural resources management.</p>
                </div>

                <div className="aboutImg-1">
                  <img src={AboutImg1} alt="" />
                </div>

                </div> {/* flex div */}

                  <div className="about-flex-box-2">
                    <div className="about-imgBox-2">
                      <img src={AboutImg2} alt="" />
                    </div>
                    <div className="about-text-box-2">
                <p className="about-text">The GEM Green Building Council, Environment and Climate Change were formed keeping in mind the importance and a greater need to engage members on the core principles of sustainable development, circular economy, and SDGs. The councils have been involved in engaging stakeholders and members for policy interventions, addressing their business concerns for better productivity and profitability. The councils have a vision for being the leading strategic, knowledge and solutions platform for members and stakeholders for achieving 5Ps (people, planet, prosperity, peace and partnership).</p>

                <p className="about-text">The GEM green building certification is an indigenous systematic evidence-based rating system for built environment including commercial and residential complexes. It is designed on the principles of NBC 2016, ECBC 2017 and selected CPWD guidelines. The objective is to ensure future buildings move towards environment friendly methods of design, construction, and operations. The GEM certification programme has so far successfully rated many projects across the country. The projects range from residential to high rise, mixed land use, interior, institutional, commercial, factory, hotel, hospital as well as public transportation including airports and railway stations.</p>
                </div>

                </div>  {/*flex end */}

                
            </div>
           
        </div>
      
      </div>

      <div className="aboutCard-background-part"></div>
      <div className="after-before-of-about">
      <div className="about-text-para-div">
      <p className="about-text">ASSOCHAM acts as an enabler to strengthen India’s economy and collaborate with the government on improving the ease of doing business in India. It intends to work to build the nation’s export competitiveness to help cross the $1-trillion mark in the not-so-distant future. It actively supports the government and industry partners to make India a global leader in tackling climate change, building energy security, and strengthening the digital ecosystem.
                </p>
                <p className="about-text">ASSOCHAM has identified four strategic priorities to drive inclusive and sustainable socio-economic growth for the country</p>
      </div>
      <div className="card-container-about">
      {cardData.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </div>
    </div>

      </div>
    </div>
  )
}

export default NewAboutUs
