// import React, { useState } from 'react';
// import '../CardPages/GemGreenCerti.css';
// import './Carbonfoot.css';
// import imgCarbon from '../assets/rating-img/Carbon Footprint.gif';
// import hoverdImg from '../assets/rating-img/carbonSvg/carbon-hoverimg.png';
// import carbonImg from '../assets/rating-img/carbonSvg/carbon-glob.jpeg';
// import cardImg1 from '../assets/rating-img/carbonSvg/city.png';
// import cardImg2 from '../assets/rating-img/carbonSvg/sustainable.png';
// import cardImg3 from '../assets/rating-img/carbonSvg/biodegradable.png';
// import cardImg4 from '../assets/rating-img/carbonSvg/solar-panel.png';
// import cardImg5 from '../assets/rating-img/carbonSvg/plant.png';
// import cardImg6 from '../assets/rating-img/carbonSvg/recycle-symbol.png';

// const CarbonFoot = () => {
//   // State to track whether the element is hovered or not
//   const [isHovered, setIsHovered] = useState(false);

//   // Function to handle mouse enter and leave
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div className="carbon-footprint-div">
     
//      <div className="bg-carbonheading">
//       <div className="GEMCP-btn-link">
//         <a href="">
//           Home <span className="separator"> &gt; </span> Carbon Footprint (CFP)
//         </a>
//       </div>

//       <div className="text-container-carbon" style={{paddingBottom: '15px'}}>
//           <h3>Carbon Footprinting Assessment (CFA)</h3>
//         </div>
     
//       </div>
//     <div className="after-before-div">
//       <div className="blur-div" id='blur-div-carbon'>
       
      
//         <div className="text-para-div" id='text-para-div-carbon'>
//           <div className="img-text-div" id="img-text-flex" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div className="img-div">
//               <img src={isHovered ? hoverdImg : carbonImg} alt="" />
//             </div>
//             <p className='text-para-text' id='text-para-carbon' >
           
//             The world reached a temperature of 1 degree rise over pre-industrial levels in <b> 2017.</b> The journey to limit it to 1.5˚C is a high priority for the businesses and policy makers across the globe.  The Emissions Gap Report 2022 finds that the world must cut emissions by 45 per cent to avoid global catastrophe. In this endeavour, one of the tools which assist in identifying, acknowledging, and assessing the GHG emissions in terms of CO2e from one’s facility and activity is undertaking a carbon foot printing exercise. The UNEP has also identified six-sectors in which diverse solutions can reduce 29-32 Gt CO2e annually and limit temperature rise to 1.5˚C.

//             </p>

//             </div>

//             <p className='text-para-text' id='text-para-carbon' >
//             <b>India </b>is also among less than 60 countries globally to submit the Long term <b> Low Emissions Development Strategy (LTLEDS)</b> during the COP27, Sharm-El-Sheikh, Egypt. <b>India</b> has already submitted its revised <b>Nationally Determined Commitments (NDC)</b> which includes the vision of <b>LiFE (Lifestyle for Environment)</b>. The <b>LTLEDS</b> show a strong resolve in positioning <b>India </b>as a global leader in low carbon lifestyle through <b>LiFE principles</b>, climate justice and sustainable lifestyles, alongside the principles of Equity and Common but Differentiated Responsibilities and Respective Capabilities (CBDR-RC).

//             </p>

//             <p className='text-para-text' id='text-para-carbon'>
//             These sectors are the industry, energy, food and agriculture, nature-based solutions, building and construction sector and transport.


//             </p>

//             <p className='text-para-text' id='text-para-carbon'>
//             Industry as well as and Buildings and Cities alone have potential to reduce 7.3 Gt and 5.9 Gt annually respectively.

//             </p>

//             <p className='text-para-text' id='text-para-carbon'>
//             The industry sector has immense potential for reducing its <b>carbon footprint</b> through energy efficiency, efficient waste management, leaner processes, utilising clean chemistry, circular supply chain, alternate materials and switching to <b>renewable power</b>.

//             </p>

//             {/* <div className="carbon-card-div">
//               <div className="carbon-cards">
//                 <img src={cardImg1} alt="" />

//               </div>
//               <div className="carbon-cards">
//                 <img src={cardImg2} alt="" />

//               </div>
//               <div className="carbon-cards">
//                 <img src={cardImg3} alt="" />

//               </div>
//               <div className="carbon-cards">
//                 <img src={cardImg4} alt="" />

//               </div>
//               <div className="carbon-cards">
//                 <img src={cardImg5} alt="" />

//               </div>
//               <div className="carbon-cards">
//                 <img src={cardImg6} alt="" />

//               </div>
              
//             </div> */}
           
//             <p className='text-para-text' id='text-para-carbon'>
//             {/* <div className="carbon-middle-img">
//               <img src={imgCarbon} alt="" />
//               <img src={carbonImg2} alt="" />

//             </div> */}
//             The building sector accounted for over <b>34 percent</b> of energy demand and around <b>37 percent</b> of energy and process-related <b>CO2 emissions in 2021</b>. <b>CO2 emissions</b> from building operations have reached an all-time high of around 10 GtCO2, around a 5% increase from 2020 and 2% higher than the previous peak in 2019. Currently, experts feel that there is much to do in this sector for achieving the net neutrality goals and the sector is not on track in line with 1.5-degree resolve.

//             </p>
//             <p className='text-para-text' id='text-para-carbon'>
//             The building and construction sector can provide a road map for decarbonisation of the sector through several innovative design, construction, and material choices.
//             </p>
            
//             <p className='text-para-text' id='text-para-carbon'>
//             <b>ASSOCHAM</b>, being an apex Indian Industry Chamber, has initiated the <b>Carbon Footprinting Certification Program</b> to complement <b>India’s Climate Change ambitions and goals</b>. <b>ASSOCHAM</b>, with the support of its vast network of regional Chambers and over 4.5 lac large, medium & small-scale industries, is taking up the challenge of climate solutions and pathways in its responsibility to Care for Mother Earth.

//             </p>

//             <p className='text-para-text' id='text-para-carbon'>
//             This <b>CFA guide</b> would also be a positive step in acknowledging, recognising and qualitatively assessing carbon emissions and ensuring that the country’s goal of net neutrality by <b>2070</b> is met through corporate leadership.

//             </p>

//             <p className='text-para-text' id='text-para-carbon'>
//             This <b>CFA guide</b> provides a comprehensive yet simple manner for estimation of carbon and potential of carbon reduction. The guide also includes references from <b>LiFE initiatives and UNFCCC </b> carbon neutral initiative which promotes the concepts of Measure, Reduce and Contribute.    
//             </p>
//             <p className='text-para-text' id='text-para-carbon'>
//             The guide offers the features such as the step-by-step evaluation for arriving at an assessment corresponding to the carbon reduction potentially achieved. It is designed to be a simple choice-based decision exercise leading to assessment of carbon emissions. It is a guide for resource efficient and circular production processes. The guide covers Scope-1, Scope-2 and Scope-3 emissions.

//             </p>

//             <p className='text-para-text' id='text-para-carbon'>
//             <b>There are several benefits of the CFA including </b> demonstration of the intent and leadership in achieving carbon neutrality. The assessment can help in developing a climate action plan for your businesses and activities. It is based on the philosophy of any establishment’s resolve and contribution towards national LiFE initiative. It would also be beneficial for showcasing commitments and action towards building a sustainable future and reduce environmental footprint. The guide should help companies including micro, small and medium enterprises to recognize improvement areas in resource conservation. 

//             </p>
//         </div>
//         </div>
//         </div>
//   </div>
  
//   )
// }

// export default CarbonFoot


// new code

import React, { useState } from 'react';
import '../CardPages/GemGreenCerti.css';
import './Carbonfoot.css';
import imgCarbon from '../assets/rating-img/Carbon Footprint.gif';
import hoverdImg from '../assets/rating-img/carbonSvg/carbon-hover.jpeg';
import carbonImg from '../assets/rating-img/carbonSvg/carbon-foot-new.jpeg';
import cardImg1 from '../assets/rating-img/carbonSvg/city.png';
import cardImg2 from '../assets/rating-img/carbonSvg/sustainable.png';
import cardImg3 from '../assets/rating-img/carbonSvg/biodegradable.png';
import cardImg4 from '../assets/rating-img/carbonSvg/solar-panel.png';
import cardImg5 from '../assets/rating-img/carbonSvg/plant.png';
import cardImg6 from '../assets/rating-img/carbonSvg/recycle-symbol.png';

const CarbonFoot = () => {
  // State to track whether the element is hovered or not
  const [isHovered, setIsHovered] = useState(false);

  // Function to handle mouse enter and leave
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="carbon-footprint-div">
      <div className="carbon-filter-div">
      
        <div className="GEMCP-btn-link">
          <a href="">
            Home <span className="separator"> &gt; </span> Carbon Footprint (CFP)
          </a>
        </div>

      


      <div className="after-before-div">

        {/* vfdvdf */}

        <div className="blur-div" id="blur-div-carbon">
        <div className="text-container-carbon" style={{ paddingBottom: '15px' }}>
          <h3 style={{color : 'white'}}>Carbon Footprinting Assessment (CFA)</h3>
        </div>
          <div className="text-para-div" id="text-para-div-carbon">
            <div
              className="img-text-div"
              id="img-text-flex"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
             <div className="para-text-of-img">
              <p className="text-para-text" id="text-para-carbon">
                The world reached a temperature of 1 degree rise over pre-industrial levels in <b>2017.</b> The
                journey to limit it to 1.5˚C is a high priority for businesses and policymakers across the globe. The
                Emissions Gap Report 2022 finds that the world must cut emissions by 45 percent to avoid global
                catastrophe. In this endeavor, one of the tools which assist in identifying, acknowledging, and assessing
                the GHG emissions in terms of CO2e from one’s facility and activity is undertaking a carbon footprinting
                exercise. The UNEP has also identified six sectors in which diverse solutions can reduce 29-32 Gt CO2e
                annually and limit temperature rise to 1.5˚C.
              </p>

              <p className="text-para-text" id="text-para-carbon">
              <b>India</b> is also among less than 60 countries globally to submit the Long-term <b>Low Emissions
              Development Strategy (LTLEDS)</b> during COP27, Sharm-El-Sheikh, Egypt. <b>India</b> has already submitted
              its revised <b>Nationally Determined Commitments (NDC)</b> which includes the vision of <b>LiFE (Lifestyle
              for Environment)</b>. The <b>LTLEDS</b> show a strong resolve in positioning <b>India</b> as a global leader
              in low carbon lifestyle through <b>LiFE principles</b>, climate justice, and sustainable lifestyles, alongside
              the principles of Equity and Common but Differentiated Responsibilities and Respective Capabilities (CBDR-RC).
            </p>
            </div>

              <div className="img-div">
                <img src={isHovered ? hoverdImg : carbonImg} alt="" />
              </div>
            </div>

            

            <p className="text-para-text" id="text-para-carbon">
              These sectors are the industry, energy, food and agriculture, nature-based solutions, building and
              construction sector, and transport.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              Industry as well as Buildings and Cities alone have the potential to reduce 7.3 Gt and 5.9 Gt annually
              respectively.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              The industry sector has immense potential for reducing its <b>carbon footprint</b> through energy efficiency,
              efficient waste management, leaner processes, utilizing clean chemistry, circular supply chains, alternate
              materials, and switching to <b>renewable power</b>.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              The building sector accounted for over <b>34 percent</b> of energy demand and around <b>37 percent</b> of energy
              and process-related <b>CO2 emissions in 2021</b>. <b>CO2 emissions</b> from building operations have reached an
              all-time high of around 10 GtCO2, around a 5% increase from 2020 and 2% higher than the previous peak in 2019.
              Currently, experts feel that there is much to do in this sector for achieving the net neutrality goals, and the
              sector is not on track in line with 1.5-degree resolve.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              The building and construction sector can provide a road map for decarbonization of the sector through several
              innovative design, construction, and material choices.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              <b>ASSOCHAM</b>, being an apex Indian Industry Chamber, has initiated the <b>Carbon Footprinting Certification Program</b>
              to complement <b>India’s Climate Change ambitions and goals</b>. <b>ASSOCHAM</b>, with the support of its vast network
              of regional Chambers and over 4.5 lakh large, medium & small-scale industries, is taking up the challenge of climate
              solutions and pathways in its responsibility to Care for Mother Earth.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              This <b>CFA guide</b> would also be a positive step in acknowledging, recognizing, and qualitatively assessing carbon
              emissions and ensuring that the country’s goal of net neutrality by <b>2070</b> is met through corporate leadership.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              This <b>CFA guide</b> provides a comprehensive yet simple manner for estimation of carbon and potential of carbon
              reduction. The guide also includes references from <b>LiFE initiatives and UNFCCC</b> carbon neutral initiative,
              which promotes the concepts of Measure, Reduce, and Contribute.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              The guide offers features such as the step-by-step evaluation for arriving at an assessment corresponding to the carbon
              reduction potentially achieved. It is designed to be a simple choice-based decision exercise leading to the assessment
              of carbon emissions. It is a guide for resource-efficient and circular production processes. The guide covers Scope-1,
              Scope-2, and Scope-3 emissions.
            </p>

            <p className="text-para-text" id="text-para-carbon">
              <b>There are several benefits of the CFA including</b> demonstration of the intent and leadership in achieving carbon
              neutrality. The assessment can help in developing a climate action plan for your businesses and activities. It is
              based on the philosophy of any establishment’s resolve and contribution towards the national LiFE initiative. It would
              also be beneficial for showcasing commitments and action towards building a sustainable future and reducing the
              environmental footprint. The guide should help companies, including micro, small, and medium enterprises, to recognize
              improvement areas in resource conservation.
            </p>
          </div>
        </div>

        {/* dsfsd */}
        </div>
      </div>
    </div>
  );
};

export default CarbonFoot;
