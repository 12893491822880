import { faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import './BottomTop.css'
import TopBottomImg from '../assets/iso-img/Go-to-Top.png'
import TopNewImg from '../assets/slider-svg/arrow-top.png'
const BottomTop = () => {
  // State to track whether the button should be visible
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 150) { // Button appears after scrolling 300px
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div> {/* Adjust height to make the page scrollable */}
     

      {/* Button to scroll to the top */}
      {isVisible && (
        // <button className='bottom-top-btn' id='bottom-top-btn'
        //   onClick={scrollToTop}
    
        // >
        //  <FontAwesomeIcon icon={faArrowUpLong} />
        // </button>
        <div className="top-bottom-logo" id='bottom-top-btn' onClick={scrollToTop}>
          <img src={TopNewImg} alt="" />
          {/* <img src={TopBottomImg} alt="" /> */}
        </div>
      )}
    </div>
  );
};

export default BottomTop;
